import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Nav, Container, Row, Col, FormControl, Button } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import Header from './header';
import './layout.scss';
import Logo from '../../static/img/logo.svg';

// Waypoint doesn't work well in iframes (e.g. CMS admin panel)
const containerStyle = {
  overflowY: 'auto',
  height: '100vh',
  overflowX: 'hidden',
};

export default ({ heading, subheading, button, children }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setScrolled(Boolean(window.location.hash));
  }, []);

  let className = 'site-container';
  if (!heading || scrolled) {
    className += ' navbar-scrolled';
  }

  return (
    <div className={className} style={containerStyle}>
      <Waypoint
        onEnter={() => setScrolled(false)}
        onLeave={() => setScrolled(true)}
      />
      <Header heading={heading} subheading={subheading} button={button} />
      <main id="content">{children}</main>
      <footer className="bg-primary pt-3 pb-4">
        <Container>
          <Row>
            <Col lg>
              <Row className="flex-column h-100">
                <Col xs="auto">
                  <img src={Logo} alt="Daexus" className="mt-5 footer-logo" />
                  <p className="text-light footer-subheading pt-4">
                    Reporting made easy
                  </p>
                </Col>
                <Col></Col>
                <Col xs="auto" className="copyright-large">
                  <p className="text-light" style={{ fontWeight: 300 }}>
                    &copy; Daexus 2020
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg>
              <Nav className="flex-column mt-lg-4">
                <Nav.Link as={Link} to="/" className="text-light">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/products/" className="text-light">
                  Catalyst Connector
                </Nav.Link>
                <Nav.Link as={Link} to="/about/" className="text-light">
                  Company
                </Nav.Link>
                <Nav.Link as={Link} to="/privacy/" className="text-light">
                  Privacy Policy
                </Nav.Link>
                <Nav.Link as={Link} to="/terms/" className="text-light">
                  Terms of Service
                </Nav.Link>
                <Nav.Link
                  href="https://medium.com/daexus"
                  className="text-light"
                >
                  Blog
                </Nav.Link>
                <Nav.Link
                  href="https://daexus.helpsite.com/"
                  className="text-light"
                >
                  Support Center
                </Nav.Link>
                <Nav.Link
                  href="mailto:support@daexus.io"
                  className="text-light"
                >
                  Contact us
                </Nav.Link>
              </Nav>
            </Col>
            <Col lg className="mt-4 mb-2">
              <Row className="flex-column h-100">
                <Col xs="auto">
                  <p className="text-light" style={{ fontWeight: 300 }}>
                    Subscribe to our updates here
                  </p>
                  <form
                    action="https://daexus.us17.list-manage.com/subscribe/post?u=67ba9ee2d2fa44c02f500054e&amp;id=48a64afab8"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    class="validate"
                    target="_blank"
                    novalidate
                  >
                    <Row>
                      <Col xs="auto">
                        <FormControl
                          type="email"
                          name="EMAIL"
                          id="mce-EMAIL"
                          placeholder="Email"
                          required
                        />
                        <div
                          style={{ position: 'absolute', left: -5000 }}
                          aria-hidden="true"
                        >
                          <input
                            type="text"
                            name="b_67ba9ee2d2fa44c02f500054e_48a64afab8"
                            tabindex="-1"
                            value=""
                          />
                        </div>
                      </Col>
                      <Col>
                        <Button variant="secondary" type="submit">
                          Subscribe
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
                <Col>
                  <br />
                </Col>
                <Col xs="auto">
                  <p className="text-light" style={{ fontWeight: 300 }}>
                    Find us on
                  </p>
                  <a href="https://facebook.com/Daexus">
                    <img src="/img/facebook-icon.png" />
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a href="https://twitter.com/DaexusCatalyst">
                    <img src="/img/twitter@2x.png" />
                  </a>
                </Col>
                <Col xs="auto" className="copyright-small">
                  <br />
                  <div className="text-light" style={{ fontWeight: 300 }}>
                    &copy; Daexus 2020
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};
