import React from 'react';
import { Link } from 'gatsby';
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import Logo from '../../static/img/logo.svg';

export default ({ heading, subheading, button }) => (
  <>
    <Navbar
      className="py-3"
      id="mainNav"
      expand="lg"
      collapseOnSelect={true}
      fixed="top"
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={Logo} alt="Daexus" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav
            className="navbar-nav ml-auto my-2 my-lg-0"
            style={{ alignItems: 'baseline' }}
          >
            <NavDropdown title="Catalyst Connector" className="dxNavDropdown">
              <NavDropdown.Item as={Link} to="/products">
                For Adobe Analytics
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/jira-products">
                For Jira
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/fbads-products">
                For Facebook Ads
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ga-products">
                For Google Analytics
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Catalyst for Enterprise"
              className="dxNavDropdown"
            >
              <NavDropdown.Item as={Link} to="/enterprise">
                For Adobe Analytics
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/jira-enterprise">
                For Jira
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/fbads-enterprise">
                For Facebook Ads
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ga-enterprise">
                For Google Analytics
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Integrations" className="dxNavDropdown">
              <NavDropdown.Item as={Link} to="/integrations">
                Adobe Analytics + Tableau
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/jira-integrations">
                Jira + Tableau
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/fbads-integrations">
                Facebook Ads + Tableau
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ga-integrations">
                Google Analytics + Tableau
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Button variant="secondary" href="https://profile.daexus.io">
                Login
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {heading && (
      <header className="masthead">
        <Container className="h-100">
          <div className="row h-100">
            <div className="col-12 align-self-end">
              <h1
                dangerouslySetInnerHTML={{ __html: heading }}
                style={{
                  backgroundColor: 'rgba(233,239,246, 0.8)',
                  display: 'inline-block',
                }}
              />
            </div>
            <div className="col-lg">
              <p
                className="text-dark font-weight-light mb-4"
                style={{
                  backgroundColor: 'rgba(233,239,246, 0.8)',
                  display: 'inline-block',
                }}
                dangerouslySetInnerHTML={{ __html: subheading }}
              />
              <div></div>
              {button && (
                <a
                  className="btn btn-outline-primary tellmemore"
                  href="#content"
                >
                  {button}
                </a>
              )}
              {heading === 'Reporting<br />made easy' && (
                <a className="mx-4 btn btn-secondary" href="/products/#online">
                  Try it out now
                </a>
              )}
            </div>
          </div>
        </Container>
      </header>
    )}
  </>
);
